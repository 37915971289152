Vue.component('rangeFilterComponent', {
    props: ['filterField'],
    data:function() {
        return {
            display:true,
            show_clearfilter:false,
            filterIntervals:[],
            renderView:false,
        };
    },
    watch: {
        '$route': 'generateIntervals',
        '$store.state.itemTimeFlag':'calculateResults',
        //'$store.state.itemUpdate': 'calculateResults'
    },
    computed:{
        filterName:function(){
            return this.filterField.ItemField;
        },

        intervalBegin:function(){
            return this.filterIntervals[0][2].name
        },
        intervalEnd:function(){
            return this.filterIntervals[this.filterIntervals.length-1][2].name
        }
    },
    methods:{
        generateIntervals:function(){
            console.log('Genereting Interval');
            if(!this.filterField)
                this.filterField="0,100,200,500,1000";
            let interval = this.filterField.FilterCollection.split(',');
            let lastElement = null;
            if(interval.length>0)
                lastElement=interval[interval.length-1];
            else
                lastElement = interval[0];
            let rangeInterval = [];
            let last=null;
            for(let inter of interval){
                let operator = null;
                let filter = {};
                filter.type = 'rangeFilter'
                filter.itemCount = 0;
                filter.hiddens=new Map();
                filter.active=false;
                if(last==null) {
                    if (inter == 0)
                        continue;
                    filter.name = `range-${this.filterField.ItemField}-less-${inter}`
                    filter.condition = `parseFloat(x.${this.filterField.ItemField})<=${inter}`;
                    filter.result = true;
                    rangeInterval.push([0, inter,filter]);
                }
                else {
                     filter.name = `range-${this.filterField.ItemField}-between-${inter}-${last}`
                     filter.condition = `parseFloat(x.${this.filterField.ItemField})<=${inter} && parseFloat(x.${this.filterField.ItemField})>=${last}`;
                     filter.result = true;
                     rangeInterval.push([last, inter,filter,'range'+uuidv4()]);
                }
                last=inter;
            }
            let Endfilter = {};
            Endfilter.type = 'rangeFilter';
            Endfilter.itemCount = 0;
            Endfilter.hiddens=new Map();
            Endfilter.active=false;
            Endfilter.name = `range-${this.filterField.ItemField}-end-${lastElement}`
            Endfilter.condition = `parseFloat(x.${this.filterField.ItemField})>${lastElement}`;
            Endfilter.result = true;
            rangeInterval.push([lastElement,lastElement,Endfilter]);
            //console.log('Rangos',rangeInterval);
            this.filterIntervals=rangeInterval;
            this.calculateResults();
        },
        calculateResults: function(){
            //console.time('Calculate Results Range For ',this.filterField.ItemField);
            let items = this.$store.getters.getItemsVisibles; //this.$store.getters.getItems;
            this.resetCountInterval();
            for(let x of items.filter(e=>e.visible==true)){
                for(let interval of this.filterIntervals) {
                    let cond = interval[2].condition;
                    let result = interval[2].result;
                    /*let tmpFunc2 = new Function("x","return "+cond);
                    let isVisible =tmpFunc2(x)==result;*/
                    let isVisible = (eval(cond) == result)
                    if(isVisible) {
                        interval[2].itemCount = interval[2].itemCount + 1
                    }
                    interval[2].active = this.$store.state.filters.indexOf(interval[2].name)!==-1;
                    //console.log(interval[2],this.$store.state.filters)
                }
            }
            //console.timeEnd('Calculate Results Range For ',this.filterField.ItemField);
        },
        resetCountInterval:function(){
            for(let interval of this.filterIntervals) {
                interval[2].itemCount = 0;
            }
        },
        async launchFilter(filters){
            //filters[2].active= ! filters[2].active;
            this.show_clearfilter=true;
            //console.log('New Filter',filters[2]);
            await this.$store.dispatch('addFilter', filters[2]);
            window.scrollTo(0, 0);
        },
        async clearFilter(){
            this.show_clearfilter=false;
            await this.$store.dispatch('restoreFilter')
            window.scrollTo(0, 0);
        },
        fetchData() {
            this.renderView=!this.renderView;
            this.calculateResults();
        },
    },
    mounted:function(){
        //.log('Intervals create')
        this.generateIntervals();
    },
    template: `<div class="filter" v-if="renderView!=null">
                    <div>
                        <h5 class="custom-title">
                            {{tr(filterField.ItemField)}}
                            <span v-show="display" @click="display = !display" class="right"><i class="icon fa fa-minus"></i></span>
                            <span v-show="!display" @click="display = !display" class="right"><i class="icon fa fa-plus"></i></span>
                        </h5>
                    </div>
                    <section v-if="display" class="filter-options price-range">
                        <ul>
                          <template v-for="interval of filterIntervals">
                                <template v-if="interval[2].itemCount>0">
                                    <li :key="'li-'+interval[2].name" v-if="interval[2].name == intervalBegin" >
                                        <input :key="'input-'+interval[2].name" v-model="interval[2].active"  type="checkbox" :id="interval[2].name"  :name="interval[2].name" @click="launchFilter(interval)"/>
                                        <label :for="interval[2].name">Hasta {{interval[1]}}</label>
                                        <label :for="interval[2].name" class="right">{{interval[2].itemCount}}</label>
                                    </li>
                                    <li :key="'li-'+interval[2].name" v-else-if="interval[2].name == intervalEnd" >
                                        <input :key="'input-'+interval[2].name" v-model="interval[2].active" type="checkbox" :id="interval[2].name" :name="interval[2].name"  @click="launchFilter(interval)"/>
                                        <label :for="interval[2].name">Más de {{interval[1]}}</label>
                                        <label :for="interval[2].name" class="right">{{interval[2].itemCount}}</label>
                                    </li>
                                    <li :key="'li-'+interval[2].name" v-else>
                                        <input :key="'input-'+interval[2].name" v-model="interval[2].active" type="checkbox" :id="interval[2].name" @click="launchFilter(interval)" />
                                        <label :for="interval[2].name">{{interval[0]}} a {{interval[1]}}</label>
                                        <label :for="interval[2].name" class="right">{{interval[2].itemCount}}</label>
                                    </li>
                                </template>
                          </template>
                        </ul>
                    </section>
                </div>`,
});
